@import "assets/scss/variables";
@import "assets/scss/utils";

.input-with-label {
  display: flex;
  flex-direction: column;
  width: 100%;

  .label-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    color: var(--text-primary);
    font-family: "Poppins", sans-serif;
    margin-bottom: 8px;
    font-size: $fontSmall;
  }

  .subtitle {
    color: $greyishBlue50;
    margin-bottom: 16px;
    font-size: $fontSmall;
  }
}

.fixed-width {
  width: 160px;
}

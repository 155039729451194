@import "assets/scss/variables";
@import "assets/scss/fonts";
@import "assets/scss/utils";
@import "assets/scss/general";

.container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  margin: 0 auto;

  .title-and-msg {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

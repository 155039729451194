@import "assets/scss/variables";
@import "assets/scss/general";
@import "assets/scss/utils";

.empty-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  margin: 88px 0;

  img {
    width: 256px;
    height: 256px;
  }

  .empty-desc {
    width: 70%;
    margin: 0 auto;
  }

  &:global(.empty-wrapper-modal) {
    margin: 0;

    img {
      width: 192px;
      height: 192px;
    }

    .container {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .empty-desc {
        width: 100%;
        font-size: $fontSmall;
      }
      h4 {
        font-size: $fontLarge;
      }
    }
  }
  button {
    @media (max-width: $screen-sm-max) {
      width: 100%;
    }
  }

  @media (max-width: $screen-sm-max) {
    margin: 48px 0;
  }
}

@import "assets/scss/variables";
@import "assets/scss/mixins";

.popup-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  box-shadow: var(--wrapper-shadow);
  z-index: 100;

  .backdrop {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: var(--wrapper-bg);
    backdrop-filter: blur(8px);
    position: fixed;
    z-index: 10;
    cursor: pointer;
  }

  .content-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    background: var(--background);
    padding: 24px;
    border-radius: 24px;
    width: 640px;
    z-index: 100;

    &.sm {
      width: 384px;
    }
    &.md {
      width: 512px;
    }

    .close-icon {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 16px;
      right: 16px;
      z-index: 1;
      &:hover {
        cursor: pointer;
      }
    }

    @media (max-width: $screen-md-max) {
      width: 90vw !important;
      max-height: 90vh !important;
      overflow: scroll;
      &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
    }
  }
}

[data-theme="light"] {
  --wrapper-bg: rgba(236, 238, 245, 0.5);
  --backdrop-filter: blur(8px);
  --wrapper-shadow: 0px 0px 32px rgba(8, 9, 24, 0.35);
}
[data-theme="dark"] {
  --wrapper-bg: rgba(8, 9, 24, 0.7);
  --backdrop-filter: blur(8px);
  --wrapper-shadow: 0px 0px 32px rgba(8, 9, 24, 0.35);
}

@import "assets/scss/variables";
@import "assets/scss/utils";

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.toast-wrapper {
  bottom: 24px;
  right: 24px;
  position: fixed;
  z-index: 101;
  max-width: calc(100vw - 48px);

  .toast-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 12px;
    gap: 12px;
    border-radius: 8px;
    background: var(--info-bg);
    color: var(--text-color);
    z-index: 100;
    pointer-events: none;
    animation: fadeIn 0.7s ease 0s normal 1;

    &.success {
      background: var(--success-bg);
    }

    &.warning {
      background: var(--warning-bg);
    }

    &.error {
      background: var(--error-bg);
    }

    .msg-wrapper {
      display: flex;
      gap: 12px;
    }

    &.with-btns {
      pointer-events: auto;
      .msg-wrapper {
        align-items: center;
      }
    }

    .icon-container {
      display: flex;
      align-items: flex-start;
    }

    .text-wrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;
      font-size: 14px;

      .title {
        font-family: "Poppins Bold", sans-serif;
      }

      .msg {
        margin: 0;
      }
    }
  }

  @media (max-width: $screen-sm-max) {
    min-width: calc(100vw - 48px);
  }
}

[data-theme="light"] {
  --text-color: #{$darkBlue100};
  --success-bg: #{$success10};
  --warning-bg: #{$warning10};
  --error-bg: #{$error20};
  --info-bg: #{$lightBlue60};
}

[data-theme="dark"] {
  --text-color: #{$lightBlue10};
  --success-bg: #{$success100};
  --warning-bg: #{$warning60};
  --error-bg: #{$error90};
  --info-bg: #{$darkBlue50};
}

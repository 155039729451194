@import "assets/scss/variables";
@import "assets/scss/general";
@import "assets/scss/utils";

.flex-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  .title-wrapper {
    display: flex;
    align-items: center;
    gap: 24px;
    flex: 1;

    h3 {
      color: var(--text-primary);
    }

    .school-logo {
      margin: -70px 0 0 40px;
      border: 3px solid var(--avatar-border);
    }

    @media (max-width: #{$screen-lg-max}) {
      flex-direction: column;
      gap: 16px;

      h3 {
        text-align: center;
        margin: 0;
      }

      .school-logo {
        margin: -100px 0 0;
      }
    }
  }

  .actions-wrapper {
    display: flex;
    gap: 12px;
    position: relative;
    @media (max-width: #{$screen-sm-max}) {
      width: 100%;
      justify-content: center;
    }
  }

  @media (max-width: #{$screen-lg-max}) {
    flex-direction: column;
  }
}

.school-details {
  margin: 0 80px;
  display: flex;
  justify-content: space-between;
  gap: 64px;

  > div {
    display: flex;
    flex-direction: column;

    &:nth-child(1) {
      flex-basis: 70%;
    }

    &:nth-child(2) {
      flex-basis: 30%;
    }

    h6 {
      margin: 0;
      color: var(--text-primary);
    }

    p {
      margin: 0 0 16px;
      display: inline-block;
    }
  }

  @media (max-width: #{$screen-lg-max}) {
    margin: 0;
    flex-direction: column;
    gap: 0;
  }
}

@import "./assets/scss/variables";
@import "./assets/scss/fonts";
@import "./assets/scss/general";
@import "./assets/scss/utils";

.AppWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--background);
  min-height: 100vh;

  /* width */
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--scrollbar);
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
  }
}

[data-theme="light"] {
  // Done
  --active-tab-bg: #{$lightBlue80};
  --active-tab-bg-hover: #{$lightBlue60};
  --sidebar-bg: #{$lightBlue30};
  --main-content-bg: #{$neutral10};
  --avatar-border: #{$neutral10};
  --dot-bg: #{$lightBlue70};
  --header-bg: rgba(255, 255, 255, 0.8);
  --divider-bg: #{$greyishBlue10};
  --accent: #{$lightBlue100};
  --tooltip-bg: #{$lightBlue50};
  --tooltip-color: #{$darkBlue100};
  --error: #{$error60};
  --text-primary: #{$darkBlue100};
  --text-primary-inverted: #{$lightBlue10};
  --background: #{$neutral10};
  --content-color: #{$greyishBlue50};
  --card-bg: #{$lightBlue30};
  --card-bg-hover: #{$lightBlue40};
  --card-bg-active: #{$lightBlue50};
  --card-bg-disabled: #{rgba($lightBlue30, 0.9)};
  --scrollbar: #{$greyishBlue10};
  --main-shadow: 0 0 32px rgba(8, 9, 24, 0.1);
  --image-shadow: 0px 0px 12px rgba(32, 36, 96, 0.1);
  --transition: all 350ms;
}

[data-theme="dark"] {
  // Done
  --active-tab-bg: #{$darkBlue60};
  --active-tab-bg-hover: #{$darkBlue90};
  --sidebar-bg: #080918;
  --main-content-bg: #{$greyishBlue100};
  --avatar-border: #{$greyishBlue100};
  --dot-bg: #{$greyishBlue50};
  --header-bg: rgba(22, 23, 37, 0.8);
  --divider-bg: #{$greyishBlue70};
  --accent: #{$lightBlue100};
  --tooltip-bg: #{$greyishBlue70};
  --tooltip-color: #{$lightBlue10};
  --error: #{$error60};
  --text-primary: #{$lightBlue10};
  --text-primary-inverted: #{$darkBlue100};
  --background: #{$greyishBlue100};
  --content-color: #{$greyishBlue30};
  --card-bg: #{$greyishBlue90};
  --card-bg-hover: #{$greyishBlue80};
  --card-bg-active: #{$greyishBlue70};
  --card-bg-disabled: #{rgba($greyishBlue90, 0.5)};
  --scrollbar: #{$greyishBlue50};
  --main-shadow: 0 0 32px rgba(8, 9, 24, 0.35);
  --image-shadow: 0px 0px 12px rgba(32, 36, 96, 0.1);
  --transition: all 350ms;
}
[data-collapsed="true"] {
  --sidebar-width: 104px;
}
[data-collapsed="false"] {
  --sidebar-width: 300px;
}

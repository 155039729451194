@import "assets/scss/variables";

.c-divider {
  background-color: var(--divider-bg);

  &--horizontal {
    width: 100%;
    height: 1px;
  }

  &--vertical {
    height: 24px;
    width: 1px;
  }
}

.transparent {
  background: transparent;
}

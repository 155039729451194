@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/general";
@import "../../../../assets/scss/utils";

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
}

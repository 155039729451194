@import "assets/scss/variables";
@import "assets/scss/general";
@import "assets/scss/utils";

.msg {
  display: flex;
  flex-direction: column;
  .role-types {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    .type {
      display: flex;
      align-items: center;
      gap: 2px;
      .info-icon {
        fill: var(--content-color);
      }
      a {
        display: flex;
        margin: 0;
      }
    }
  }
}

@import "assets/scss/variables";
@import "assets/scss/utils";

.success-popup {
  .title {
    margin: 0 0 8px;
  }

  .card {
    margin: 36px auto;
    width: 50%;

    @media (max-width: $screen-sm-max) {
      margin: 24px auto;
      width: 70%;
    }
  }
  .buttons-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 24px;

    button {
      flex: 1 1;
    }

    @media (max-width: $screen-sm-max) {
      flex-direction: column-reverse;
      gap: 12px;
    }
  }
}

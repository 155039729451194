@import "assets/scss/variables";
@import "assets/scss/fonts";

.header {
  color: var(--text-primary);
  font-family: "Poppins Bold", sans-serif;
  font-size: $fontH3;
}

.progress-bar {
  display: grid;
  grid-template-rows: auto auto;
  align-items: center;
  width: 100%;
  margin-bottom: 48px;
  gap: 8px;

  .step {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    border-radius: 16px;
    height: 24px;
    width: 24px;
    background-color: $orange60;
    color: $white;
    font-family: "Poppins Medium", sans-serif;
    font-size: $fontXSmall;
    cursor: pointer;
  }

  .disabled-step {
    background-color: var(--disabled-step-bg);
    cursor: default;
  }

  .line {
    width: 100%;
    height: 1px;
    background-color: var(--progress-bar-bg);
  }

  .step-title {
    font-size: $fontXSmall;
    text-align: center;
    white-space: nowrap;
    color: var(--next-btn-color);
    display: flex;
    justify-content: center;

    @media (max-width: $screen-sm-max) {
      white-space: initial;
    }
  }
  .disabled-title {
    color: $greyishBlue50;
  }

  @media (max-width: #{$screen-sm-max}) {
    margin-bottom: 0;
  }
}

[data-theme="light"] {
  --progress-bar-bg: #{$lightBlue70};
  --next-btn-color: #{$greyishBlue90};
  --disabled-step-bg: #{$orange20};
  --row-title-color: #{$greyishBlue30};
  --row-img-title-color: #{$lightBlue10};
}
[data-theme="dark"] {
  --progress-bar-bg: #{$greyishBlue70};
  --next-btn-color: #{$lightBlue10};
  --disabled-step-bg: #{$greyishBlue90};
  --row-title-color: #{$greyishBlue30};
  --row-img-title-color: #{$lightBlue10};
}

@import "assets/scss/variables";
@import "assets/scss/general";
@import "assets/scss/utils";

.main-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  .inputs-container {
    display: flex;
    gap: 8px;
    .color-input {
      border: 1px solid $greyishBlue50;
      border-radius: 8px;
      height: 48px;
      width: 48px;
      cursor: pointer;
    }
    .selected {
      border: 2px solid $orange60;
    }
  }
}

@import "assets/scss/variables";

.pagination-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  .items-amount {
    white-space: nowrap;
  }
  .pagination-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    button {
      padding: 0;
      width: 32px;
      height: 32px;
      border-radius: 8px;
    }

    .dots {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
    }

    .inactive {
      &:not(:hover) {
        background: unset;
      }
    }
  }
  .show-pages-btn {
    margin: 12px 0 12px auto;

    @media (max-width: #{$screen-md-max}) {
      margin: 0;
    }
  }

  @media (max-width: #{$screen-md-max}) {
    flex-direction: column;
  }

}

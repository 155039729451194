@import "assets/scss/variables";
@import "assets/scss/utils";
@import "assets/scss/general";

.input-with-label {
  display: flex;
  flex-direction: column;
  width: 100%;

  .input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 8px;
    width: 100%;
    input {
      &:focus {
        outline: none;
      }
    }
  }
  .subtitle {
    font-size: $fontSmall;
  }
}

.date-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
  width: 100%;

  .label-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    color: var(--text-primary);

    .tooltip-text {

      display: flex;
      align-items: center;
      font-weight: 400;
      font-family: "Poppins", sans-serif;
      margin: 0 0 0 4px;
    }

    .tooltip {
      background-color: var(--tooltip-bg);
      border: 1px solid var(--tooltip-bg);
      border-radius: 0;
      padding: 15px 12px;
      font-family: "Poppins", sans-serif;
      font-size: $fontXSmall;
      font-weight: 400;
      max-width: 280px;
      color: var(--text-primary);
    }
  }

  .datePicker {
    button {
      margin-right: 40px;
      &::after {
        background-color: $orange60;
        color: var(--background);
        font-size: $fontMedium;
      }
    }

    .date-input {
      display: flex;
      align-items: center;
      position: relative;
      .input {
        border-radius: 16px;
        padding: 15px 16px 15px 20px;
        width: 100%;
        border: unset;
        background-color: var(--date-bg);
        color: var(--text-primary);
        transition: background-color 350ms;

        &:hover {
          outline: 1px solid var(--outline-color);
          outline-offset: -1px;
          transition: unset;
        }
        &:focus {
          background-color: var(--date-bg-focus);
          outline: 2px solid var(--outline-color);
          outline-offset: -2px;
          transition: unset;
        }
        &:disabled {
          outline: unset;
          background-color: var(--date-bg);
        }
      }
    }
  }
}

.uploaded-file-field {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  width: 100%;
  color: $greyishBlue50;
  height: 48px;
  font-size: $fontSmall;

  > div {
    word-break: break-word;
  }

  svg {
    flex-shrink: 0;
    cursor: pointer;
  }
}

.custom-file-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  font-family: "Poppins SemiBold", sans-serif;
  font-size: $fontSmall;
  color: var(--text-primary);
  cursor: pointer;
  input[type="file"] {
    display: none;
  }
}

.hidden {
  display: none;
}

[data-theme="light"] {
  --outline-color: #{$lightBlue90};
  --date-bg: #{$lightBlue10};
  --date-bg-focus: #{$lightBlue20};
}

[data-theme="dark"] {
  --outline-color: #{$greyishBlue60};
  --date-bg: #{$greyishBlue90};
  --date-bg-focus: #{$greyishBlue80};
}

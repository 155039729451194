@import "assets/scss/variables";
@import "assets/scss/fonts";
@import "assets/scss/utils";
@import "assets/scss/general";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin: 0 auto;

  .form {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;

    .options {
      display: flex;
      justify-content: space-between;

      .forgotten-password {
        font-family: "Poppins Medium", sans-serif;
        font-size: 14px;
        color: var(--accent);
        &:hover {
          cursor: pointer;
        }
      }
    }
    .btns-container {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }
  }
  .registration-section {
    font-size: $fontSmall;
    text-align: center;
    color: var(--text-primary);
    p {
      margin: 0 0 12px;
    }
    span {
      color: var(--accent);
      cursor: pointer;
    }
  }
}

[data-theme="light"] {
  --check-border: #{$greyishBlue30};
}

[data-theme="dark"] {
  --check-border: #{$greyishBlue50};
}

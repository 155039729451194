@import "assets/scss/variables";

.c-badge {
  border-radius: 6px;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  width: fit-content;
  font-family: "Poppins Bold", sans-serif;
  color: $lightBlue10;
  background: $orange60;
  font-size: $fontSmall;
  padding: 4px 10px;

  svg {
    fill: $lightBlue10;
  }

  &--open {
    color: $darkBlue100;
    background: $warning60;

    svg {
      fill: $darkBlue100;
    }
  }

  &--danger {
    background: $error50;
  }

  &--small {
    font-size: $fontXSmall;
    padding: 2px 6px;

    svg {
      width: 14px;
      height: 14px;
    }
  }
}

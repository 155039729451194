@import "assets/scss/variables";
@import "assets/scss/general";
@import "assets/scss/utils";

.flex-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .headline-wrapper {
    display: flex;
    align-items: center;
    gap: 24px;

    .title-wrapper {
      display: flex;
      flex-direction: column;
      gap: 6px;
      overflow-wrap: anywhere;
    }
    @media (max-width: #{$screen-sm-max}) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .headline-buttons {
    display: flex;
    align-items: center;
    gap: 12px;

    @media (max-width: #{$screen-sm-max}) {
      flex-direction: column;
      width: 100%;
    }
  }

  @media (max-width: #{$screen-lg-max}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }

  @media (max-width: #{$screen-sm-max}) {
    button {
      width: 100%;
    }
  }
}

.details {
  margin: 30px 40px;
  display: flex;
  flex-direction: column;

  &-subtitle {
    font-family: "Poppins Regular", sans-serif;
    font-weight: 400;
    margin: 0 0 4px;
    color: var(--content-color);
    font-size: $fontXSmall;
  }

  &-content-row {
    display: flex;
    align-items: center;
  }

  &-content {
    font-family: "Poppins Bold", sans-serif;
    color: var(--text-primary);
    font-size: $fontSmall;
    margin: 0 0 16px;
    display: flex;
    align-items: center;
    gap: 4px;

    &--light {
      font-family: "Poppins Regular", sans-serif;
    }
  }

  &-content-wrapper {
    display: inline-flex;
    flex-direction: column;
    gap: 2px;
    margin: 0 0 16px;

    .details-content {
      margin: 0;
    }

    .u-link {
      margin: 0 6px;

      svg {
        fill: var(--text-primary)
      }
    }

  }

  @media (max-width: #{$screen-lg-max}) {
    margin: 24px 0;
  }
}

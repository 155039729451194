@font-face {
  font-family: "Poppins";
  src: url("../../../public/fonts/Poppins.ttf");
}

@font-face {
  font-family: "Poppins Semibold";
  src: url("../../../public/fonts/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Poppins Bold";
  src: url("../../../public/fonts/Poppins-Bold.ttf");
}

@font-face {
  font-family: "Poppins Regular";
  src: url("../../../public/fonts/Poppins-Regular.ttf");
}
@font-face {
  font-family: "Poppins Medium";
  src: url("../../../public/fonts/Poppins-Medium.ttf");
}
@font-face {
  font-family: "Poppins Light";
  src: url("../../../public/fonts/Poppins-Light.ttf");
}
@font-face {
  font-family: "Poppins Extra Light";
  src: url("../../../public/fonts/Poppins-ExtraLight.ttf");
}

@font-face {
  font-family: "EBGaramond Semibold";
  src: url("../../../public/fonts/EBGaramond-SemiBold.ttf");
}

@font-face {
  font-family: "EBGaramond Bold";
  src: url("../../../public/fonts/EBGaramond-Bold.ttf");
}

@font-face {
  font-family: "EBGaramond Regular";
  src: url("../../../public/fonts/EBGaramond-Regular.ttf");
}
@font-face {
  font-family: "EBGaramond Medium";
  src: url("../../../public/fonts/EBGaramond-Medium.ttf");
}

@import "assets/scss/variables";
@import "assets/scss/general";
@import "assets/scss/utils";

.status-wrapper {
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--text-primary)
}

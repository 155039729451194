@import "assets/scss/variables";
@import "assets/scss/general";
@import "assets/scss/utils";

.content-wrapper--public {
  width: 100%;
  min-height: 100vh;
  padding: 60px;
  background: var(--background);

  .headline-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;

    &__user-info {
      display: flex;
      align-items: center;
      gap: 24px;

      .title-wrapper {
        display: flex;
        flex-direction: column;
        overflow-wrap: anywhere;

        h3 {
          font-size: $fontSmall;
        }
      }
    }
  }

  @media (max-width: $screen-lg-max) {
    padding: 24px;
  }
}

.content-wrapper--slideshow {
  display: flex;
  align-items: center;
  background: var(--background);
  width: 100%;

  > div {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .card-pic img {
    width: auto;
    height: 100%;
    border-radius: 24px;

    @media (max-width: $screen-xl-max) {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      border-radius: 16px;
    }

    @media (max-width: $screen-sm-max) {
      border-radius: 8px;
    }
  }
}

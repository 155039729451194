@import "assets/scss/variables";
@import "assets/scss/general";
@import "assets/scss/utils";

.page-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  min-height: 40px;
  @media (max-width: $screen-lg-max) {
    flex-direction: column;
    align-items: flex-start;
  }

  .page-title {
    display: flex;
    align-items: center;
    gap: 12px;

    .u-title {
      color: var(--text-primary);
      white-space: nowrap;
      @media (max-width: $screen-xs-max) {
        white-space: unset;
      }
    }

    .u-title-details {
      font-size: $fontMedium;
      white-space: nowrap;
    }
  }

  .page-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 10px;
    @media (max-width: $screen-lg-max) {
      justify-content: flex-start;
    }

    .sort-and-children {
      display: flex;
      align-items: center;
      gap: 10px;

      :global(.date-filter) {
        width: 40px;
        height: 40px;
      }

      .mobile-sort-menu-wrapper {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100vw;
        z-index: 9;
        max-height: 0px;
        transition: max-height 300ms;
        .mobile-sort-menu-container {
          display: flex;
          flex-direction: column;
          gap: 16px;
          padding: 24px;
          background: var(--sort-menu-mobile-bg);
          backdrop-filter: blur(12px);
          border-radius: 24px 24px 0px 0px;
          .heading {
            display: flex;
            gap: 8px;
            align-items: center;
          }
          .buttons-wrapper {
            display: flex;
            align-items: center;
            gap: 8px;
            flex-grow: 1;

            button {
              border: var(--sort-btn-border);
              &:active {
                background-color: var(--sort-btn-active-bg);
              }
            }
            .active {
              border: var(--sort-btn-border);
              background-color: var(--sort-btn-active-bg);
            }
          }
          .options-container {
            display: flex;
            flex-direction: column;
            gap: 8px;
            button {
              font-size: $fontSmall;
              height: 48px;
              justify-content: flex-start;
              padding: 0 16px;
              background: transparent;
              color: var(--text-primary);
              &:hover {
                background: var(--sort-option-hover);
              }
            }
            .selected {
              background: var(--sort-option-selected) !important;
            }
          }
        }
      }
      .open {
        max-height: 500px;
        transition: max-height 300ms;
      }
    }
    .hide-sort-and-children {
      @media (max-width: $screen-xl-max) {
        display: none !important;
      }
    }
  }

  &:global(.divider-hidden) {
    .sort-and-children {
      > div:last-child {
        display: none;
      }
    }
  }

  &:global(.divider-hidden--mobile) {
    .sort-and-children {
      > div:last-child {
        @media (max-width: $screen-lg-max) {
          display: none;
        }
      }
    }
  }
}

[data-theme="light"] {
  --sort-btn-border: 1px solid #{$greyishBlue40};
  --sort-btn-active-bg: rgba(36, 38, 58, 0.03);
  --sort-menu-mobile-bg: rgba(245, 247, 255, 0.8);
  --sort-option-hover: #{$lightBlue40};
  --sort-option-selected: #{$lightBlue40};
}
[data-theme="dark"] {
  --sort-btn-border: 1px solid #{$greyishBlue50};
  --sort-btn-active-bg: rgba(229, 232, 255, 0.1);
  --sort-menu-mobile-bg: rgba(36, 38, 58, 0.8);
  --sort-option-hover: #{$greyishBlue60};
  --sort-option-selected: #{$greyishBlue70};
}

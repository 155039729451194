@import "assets/scss/variables";
@import "assets/scss/utils";
@import "assets/scss/general";

.switch-container {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  .switch {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 20px;
    input {
      opacity: 0;
      width: 32px;
      height: 20px;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--bg);
      border-radius: 40px;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      left: 2px;
      top: 2px;
      border-radius: 50%;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: $orange60;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(12px);
      -ms-transform: translateX(12px);
      transform: translateX(12px);
    }
  }

  .label {
    white-space: nowrap;
    font-size: $fontSmall;
    font-weight: $weightSemiBold;
    color: var(--text-primary);
  }
}

[data-theme="light"] {
  --bg: #{$lightBlue30};
}

[data-theme="dark"] {
  --bg: #{$greyishBlue90};
}

@import "assets/scss/variables";
@import "assets/scss/utils";

.managers-popup {
  .title {
    margin: 0 0 8px;
  }

  .content {
    margin: 24px auto;
    text-align: center;
    max-height: 256px;
    overflow-y: scroll;

    .actions-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;
      margin: 12px 0;
    }
  }

  .buttons-container {
    display: flex;
    justify-content: center;
    gap: 12px;
    button {
      width: 50%;
    }

    @media (max-width: $screen-sm-max) {
      flex-direction: column-reverse;

      button {
        width: 100% !important;
      }
    }
  }
}

@import "assets/scss/variables";
@import "assets/scss/utils";

.mint-fee-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border: 1px solid var(--divider-bg);
  border-radius: 8px;
  .fee {
    display: flex;
    align-items: center;
    gap: 4px;
    font-family: "Poppins Bold";
    color: var(--text-primary);
  }
}

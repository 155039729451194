@import "assets/scss/variables";
@import "assets/scss/general";
@import "assets/scss/utils";

.tabs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 18px;
  color: var(--tab-color);
  font-weight: $weightSemiBold;
  font-size: $fontSmall;
  border-bottom: 1px solid var(--border-color);
  height: 44px;
  .tab {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    height: 100%;

    .circle {
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background-color: $orange60;
    }
  }
  .tab-active {
    border-bottom: 3px solid $orange60;
  }
}

[data-theme="light"] {
  --tab-color: #{$greyishBlue20};
  --border-color: #{$lightBlue50};
}
[data-theme="dark"] {
  --tab-color: #{$greyishBlue70};
  --border-color: #{$greyishBlue80};
}

@import "assets/scss/variables";
@import "assets/scss/general";
@import "assets/scss/utils";

.card {
  position: relative;
  flex: 0 1 calc(33.33% - 12px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  cursor: pointer;

  &--public {
    flex: 0 1 calc(25% - 12px);
  }

  &-pic {
    width: 100%;
    aspect-ratio: 1.333 / 1;
    overflow: hidden;
    border-radius: 12px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 12px;
      transition: transform .35s;
    }

    &--empty {
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--card-bg);
      border-radius: 12px;
      transition: background-color .35s;

      svg {
        fill: var(--card-svg);
        width: 20%;
        height: 20%;
      }

      &:hover {
        background: var(--card-bg-hover);
      }

      &:active {
        background: var(--card-bg-active);
      }
    }

    &:hover {
      img {
        transform: scale(1.05);
      }
    }
  }

  &-content {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    .u-title {
      color: var(--text-primary);
      word-break: break-word;
      display: flex;
      gap: 6px;

      > svg {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        fill: var(--text-primary);
      }
    }

    button {
      margin-top: 2px;
    }

    .c-menu-wrapper {
      top: 10px;
      right: 0;
      width: 180px;
    }
  }

  @media (max-width: #{$screen-xl-max}) {
    flex: 0 1 calc(50% - 12px);
  }

  @media (max-width: #{$screen-md-max}) {
    flex: 0 1 calc(100% - 12px);
  }
}

[data-theme="light"] {
  --card-bg: #{$lightBlue30};
  --card-svg: #{$greyishBlue20};
}

[data-theme="dark"] {
  --card-bg: #{$greyishBlue90};
  --card-svg: #{$greyishBlue50};
}

// Colors
$white: #ffffff;
$whiteWashed: #f6f6f6;
$black: #000000;
$blackWashed: #1d1d1d;

$orange10: #ffeee0;
$orange20: #ffcda6;
$orange30: #ffb986;
$orange40: #ffa15e;
$orange50: #ff9040;
$orange60: #ff7715;
$orange70: #cc5500;
$orange80: #a04300;
$orange90: #702f00;
$orange100: #582500;

$darkBlue10: #5d68f8;
$darkBlue20: #4851d8;
$darkBlue30: #363eb0;
$darkBlue40: #313893;
$darkBlue50: #282d75;
$darkBlue60: #202460;
$darkBlue70: #1b1e4b;
$darkBlue80: #161839;
$darkBlue90: #0f1029;
$darkBlue100: #080918;

$greyishBlue10: #d5d8f3;
$greyishBlue20: #bcbfdf;
$greyishBlue30: #a6a9c4;
$greyishBlue40: #8f92b1;
$greyishBlue50: #6f749e;
$greyishBlue60: #4f5582;
$greyishBlue70: #41456c;
$greyishBlue80: #313451;
$greyishBlue90: #24263a;
$greyishBlue100: #161725;

$lightBlue10: #fbfcff;
$lightBlue20: #f9faff;
$lightBlue30: #f5f7ff;
$lightBlue40: #edefff;
$lightBlue50: #e6e8ff;
$lightBlue60: #dcdfff;
$lightBlue70: #ced2ff;
$lightBlue80: #bcc1ff;
$lightBlue90: #9fa6ff;
$lightBlue100: #858dff;

$neutral10: #ffffff;
$neutral20: #e1e1e1;
$neutral30: #c3c3c3;
$neutral40: #949494;
$neutral50: #717070;
$neutral60: #565656;
$neutral70: #3c3c3c;
$neutral80: #1d1d1d;
$neutral90: #131313;
$neutral100: #000000;

$success10: #e3ffeb;
$success20: #a6f3bb;
$success30: #7ce69d;
$success40: #53d47f;
$success50: #29bc63;
$success60: #0da048;
$success70: #048b3a;
$success80: #00722d;
$success90: #005720;
$success100: #003a14;

$warning10: #FFF7E4;
$warning20: #FFF0CB;
$warning30: #FFE8AC;
$warning40: #FFD97A;
$warning50: #FFCD4E;
$warning60: #FFBE16;
$warning70: #E7A600;
$warning80: #986D00;
$warning90: #5F4400;
$warning100: #352600;

$error10: #fff5f7;
$error20: #ffccd2;
$error30: #ff7c92;
$error40: #ff5376;
$error50: #fd2b5e;
$error60: #ea1548;
$error70: #c40e3a;
$error80: #9d092c;
$error90: #66031b;
$error100: #281117;

// font-families

// font-sizes
$fontTiny: 10px;
$fontXSmall: 12px;
$fontSmall: 14px;
$fontMedium: 16px;
$fontLarge: 18px;
$fontXLarge: 20px;
$fontH4: 24px;
$fontH3: 32px;
$fontH2: 48px;
$fontH1: 64px;

// font-weights
$weightNormal: 400;
$weightMedium: 500;
$weightSemiBold: 600;
$weightBold: 700;
$weightExtraBold: 800;

// Maximum width of sections
$maxSectionWidth: 1440px;

// Small smartphones
$screen-xs-max: 375px;

// Small tablets and large smartphones (landscape view)
$screen-sm-max: 576px;

// Small tablets (portrait view)
$screen-md-max: 768px;

// Tablets and small desktops
$screen-lg-max: 992px;

// Large tablets and desktops
$screen-xl-max: 1230px;

// Extra large desktops
$screen-xxl-max: 1440px;

@import "assets/scss/variables";
@import "assets/scss/utils";

.managers-popup {
  .title {
    margin: 0 24px 8px;
  }

  .content {
    margin: 24px auto;
    text-align: center;

    .actions-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;
      margin: 12px 0;
    }
  }
}

@import "assets/scss/variables";
@import "assets/scss/utils";

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.template-msg-wrapper {
  .template-msg {
    margin-top: 12px;

    a {
      font-family: "Poppins SemiBold", sans-serif;
      color: var(--text-primary);
      display: inline-flex;
      align-items: baseline;
      margin: 0;
      svg {
        fill: var(--text-primary);
        align-self: normal;
      }
    }
  }
}

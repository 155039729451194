@import "assets/scss/variables";
@import "assets/scss/utils";

.providers-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .provider-btn {
    display: flex;
    justify-content: flex-start;
    div {
      display: flex;
      align-items: center;
      gap: 12px;
    }
    border-radius: 16px;
    height: 64px;
  }
}
.wallet-manual-msg {
  margin-top: 16px;
  font-size: $fontSmall;
  color: var(--content-color);
  span {
    cursor: pointer;
  }
}

@import "assets/scss/variables";
@import "assets/scss/general";
@import "assets/scss/utils";

.msg-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

@import "assets/scss/variables";

.wrapper {
  background-color: var(--background);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  max-height: 100vh;
  overflow: auto;
  position: relative;
  transition: background-color 350ms;

  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0 60px;

    @media (max-width: #{$screen-lg-max}) {
      padding: 0 48px;
    }
    @media (max-width: #{$screen-sm-max}) {
      padding: 0 24px;
    }
  }

  .switcher {
    position: fixed;
    bottom: 24px;
    left: 24px;

    @media (max-width: #{$screen-lg-max}) {
      display: none;
    }
  }
}

@import "assets/scss/variables";
@import "assets/scss/fonts";
@import "assets/scss/utils";

.table-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  position: relative;
  z-index: 0;

  .table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    thead {
      tr {
        background: var(--th-bg);
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid var(--th-border-col);
        th {
          display: flex;
          align-items: center;
        }
      }
      th {
        padding: 12px 16px;
        font-size: $fontXSmall;
        text-align: left;
        font-weight: $weightNormal;
        color: $greyishBlue30;

        &:first-child {
          border-radius: 8px 0 0 0;
        }
        &:last-child {
          border-radius: 0 8px 0 0;
        }
      }
    }
    tbody {
      .row {
        display: flex;
        align-items: center;
        border-bottom: 1px solid var(--row-selected-border);

        td {
          padding: 20px 16px;
          font-size: $fontSmall;
          text-align: left;
          position: relative;
          flex-grow: 1;

          .user-icon-bg {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--th-border-col);
            width: 48px;
            height: 48px;
            border-radius: 50%;
          }
        }
        &:hover {
          background-color: var(--row-bg-hover);
        }
      }
      .selected {
        background-color: var(--row-selected-bg);
      }
      .diplomas-border {
        border-bottom: 1px solid var(--row-selected-border);
      }
    }
  }

  :global(.table-sm) {
    thead {
      th {
        height: 40px;
        padding: 2px 10px;
      }
    }
    tbody {
      .row {
        td {
          padding: 8px 10px;
        }
      }
    }
  }

  .pagination {
    justify-content: flex-end;
  }

  [data-theme="light"] {
    --th-bg: #{$lightBlue30};
    --th-border-col: #{$lightBlue50};
    --row-selected-bg: #{$lightBlue20};
    --row-selected-border: #{$lightBlue50};
    --row-bg-hover: #{$lightBlue20};
  }
  [data-theme="dark"] {
    --th-bg: #{$greyishBlue90};
    --th-border-col: #{$greyishBlue80};
    --row-selected-bg: #{$greyishBlue90};
    --row-selected-border: #{$greyishBlue80};
    --row-bg-hover: #{$greyishBlue90};
  }
}

@import "assets/scss/variables";
@import "assets/scss/utils";

.reject-popup {
  position: relative;
  display: flex;
  flex-direction: column;

  .message-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

@import "assets/scss/variables";
@import "assets/scss/utils";
@import "assets/scss/general";

.c-timepicker {
  :global(.react-datepicker) {
    background: var(--timepicker-bg);
    color: var(--timepicker-color);
    box-shadow: var(--main-shadow);
    border: none;
    border-radius: 16px;
    font-family: "Poppins Regular", sans-serif;
  }
  :global(.react-datepicker__header) {
    background-color: transparent;
    border: none;
    padding: 16px 0;
    :global(.react-datepicker-time__header) {
      color: var(--timepicker-color);
      font-family: "Poppins Bold", sans-serif;
      font-size: $fontSmall;
    }
  }
  :global(.react-datepicker__time-container .react-datepicker__time) {
    background: var(--timepicker-bg);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    margin-bottom: 16px;
  }
  :global(.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item) {
    margin: 4px 8px;
    border-radius: 8px;
  }
  :global(.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover) {
    background-color: var(--timepicker-hover-color);
  }
  :global(.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled) {
    color: var(--timepicker-disabled-color);
  }
  :global(.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover) {
    background-color: transparent;
  }
  :global(.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected) {
    background-color: $orange60;
  }
  :global(.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover) {
    background-color: $orange50;
  }
  :global(.react-datepicker__triangle) {
    display: none;
  }
}

[data-theme="light"] {
  --timepicker-bg: #{$neutral10};
  --timepicker-color: #{$darkBlue100};
  --timepicker-disabled-color: #{$greyishBlue30};
  --timepicker-hover-color: #{$lightBlue40};
}

[data-theme="dark"] {
  --timepicker-bg: #{$greyishBlue80};
  --timepicker-color: #{$lightBlue10};
  --timepicker-disabled-color: #{$greyishBlue50};
  --timepicker-hover-color: #{$greyishBlue60};
}

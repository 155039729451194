@import "assets/scss/variables";
@import "assets/scss/fonts";
@import "assets/scss/utils";
@import "assets/scss/general";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 32px;
  margin: 0 auto;
  padding: 140px 0 60px;

  @media (max-width: $screen-lg-max) {
    padding: 100px 0 60px;
  }
}

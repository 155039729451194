@import "assets/scss/variables";
@import "assets/scss/general";
@import "assets/scss/utils";

.card {
  position: relative;
  flex: 0 1 calc(33% - 12px);
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  padding: 16px;
  background: var(--card-bg);
  cursor: pointer;
  transition: background-color .2s;

  h6 {
    color: var(--text-primary);
  }

  .u-website {
    word-break: break-word;
  }

  &:hover {
    background: var(--card-bg-hover);
  }

  &:active {
    background: var(--card-bg-active);
  }

  .badge-wrapper {
    position: absolute;
    right: 12px;
    bottom: 12px;
  }

  &--disabled {
    background: var(--card-bg-disabled);
    cursor: auto;

    &:hover {
      background: var(--card-bg-disabled);
    }

    h6 {
      opacity: .5;
    }

    .u-website {
      opacity: .5;
    }
  }

  @media (max-width: #{$screen-xl-max}) {
    flex: 0 1 calc(50% - 12px);
  }

  @media (max-width: #{$screen-lg-max}) {
    flex: 0 1 calc(100% - 12px);
    flex-direction: column;

    > div {
      text-align: center;
    }

    .badge-wrapper {
      position: relative;
      right: 0;
      bottom: 0;
    }
  }
}


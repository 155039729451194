@import "assets/scss/variables";
@import "assets/scss/fonts";
@import "assets/scss/general";

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 32px;
  align-self: center;
  padding: 140px 0 60px;
}

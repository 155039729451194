@import "assets/scss/variables";
@import "assets/scss/general";
@import "assets/scss/utils";

.divider {
  width: 8px;
  height: 1px;
  background-color: var(--divider-bg);
  position: absolute;
  left: 50%;
  margin-left: -4px;
  bottom: 30px;
}

.step .custom-checkbox {
  font-family: "Poppins", sans-serif;
  font-size: $fontMedium;

  &__desc {
    font-size: $fontSmall;
    color: $greyishBlue50;
  }
}

.save-as-draft {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--save-as-draft-bg);
  padding: 12px;
  border-radius: 12px;
  color: var(--text-primary);
  cursor: default;

  .msg {
    display: flex;
    align-items: center;
    gap: 8px;
    a {
      display: block;
      height: 20px;
    }
    svg {
      width: 20px;
      height: 20px;
      fill: var(--text-primary);
    }
  }

  @media (max-width: $screen-sm-max) {
    flex-direction: column;
    gap: 16px;
  }
}

[data-theme="light"] {
  --save-as-draft-bg: #{$lightBlue30};
}

[data-theme="dark"] {
  --save-as-draft-bg: #{$greyishBlue90};
}

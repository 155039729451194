@import "assets/scss/variables";
@import "assets/scss/fonts";
@import "assets/scss/utils";

.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: var(--main-content-bg);

  .grid-container {
    display: flex;
    height: 100vh;

    .dark {
      background-image: url("../../../assets/images/login-page/background-left-col-dark.png");
    }

    .light {
      background-image: url("../../../assets/images/login-page/background-left-col-light.png");
    }

    .left-col {
      flex: 1.5;
      display: flex;
      flex-direction: column;
      padding: 24px;
      background-position: center;
      background-size: cover;
      transition: background-image 350ms;
      height: 100%;
      position: relative;
      overflow-y: hidden;

      .c-logo {
        align-self: flex-start;
        cursor: pointer;
      }

      @media (max-width: #{$screen-lg-max}) {
        display: none;
        overflow-y: scroll;
      }
    }
    .right-col {
      flex: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 32px 24px;
      height: 100%;
      background-color: var(--main-content-bg);
      transition: background-color 350ms;
      position: relative;
      overflow-y: hidden;

      .right-col-header {
        display: none;

        @media (max-width: #{$screen-lg-max}) {
          display: block;
        }
      }

      @media (max-width: #{$screen-lg-max}) {
        display: block;
        margin: 0 auto;
        padding: 100px 24px 60px;
        overflow-y: scroll;
      }
    }
  }
}

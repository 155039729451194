@import "variables";

// General
.content-wrapper {
  padding: 0 48px 24px 48px;
  color: var(--content-color);
  display: flex;
  flex-direction: column;
  gap: 24px;

  .u-title {
    color: var(--text-primary);
  }

  p {
    white-space: pre-line;
  }

  .actions-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 16px;

    @media (max-width: #{$screen-lg-max}) {
      flex-direction: column;
    }
  }

  @media (max-width: #{$screen-lg-max}) {
    padding: 24px;
  }
}

// General form layout
.form-layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 32px;

  .title {
    font-family: "Poppins Light", sans-serif;
    color: var(--text-primary);
    font-size: 24px;
  }

  .body {
    .step {
      display: flex;
      flex-direction: column;
      margin: 0;
      gap: 32px;
      width: 100%;

      .tooltip-btn {
        display: inline-block;
      }
    }

    .input-group-wrapper {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    .input-group {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      margin-bottom: 8px;
      position: relative;

      > div {
        &:nth-child(1) {
          flex-basis: 40%;
        }

        &:nth-child(2) {
          flex-basis: 60%;
        }
      }

      .trash-btn--abs {
        position: absolute;
        top: 50%;
        margin-top: -10px;
        right: -36px;

        @media (max-width: $screen-lg-max) {
          display: none;
        }
      }

      &-full-width {
        > div {
          &:nth-child(1) {
            flex-basis: 100%;
          }
        }
      }

      @media (max-width: $screen-sm-max) {
        flex-direction: column;
      }
    }

    .trash-btn {
      display: none;

      @media (max-width: $screen-lg-max) {
        display: block;
        margin: 8px 0 16px;
      }

      @media (max-width: $screen-sm-max) {
        margin: 8px auto 16px;
      }
    }

    .input-group-24-38-38 {
      > div {
        &:nth-child(1) {
          flex-basis: 24%;
        }

        &:nth-child(2) {
          flex-basis: 38%;
        }

        &:nth-child(3) {
          flex-basis: 38%;
        }
      }
    }

    .input-group-25-25-25-25 {
      > div {
        &:nth-child(1) {
          flex-basis: 25%;
        }

        &:nth-child(2) {
          flex-basis: 25%;
        }

        &:nth-child(3) {
          flex-basis: 25%;
        }

        &:nth-child(4) {
          flex-basis: 25%;
        }
      }
    }

    .input-group-100 {
      > div {
        &:nth-child(1) {
          flex-basis: 100%;
        }
      }
    }

    // TODO - refactor with flex 1
    .input-group-half {
      > div {
        &:nth-child(1) {
          flex-basis: 50%;
        }

        &:nth-child(2) {
          flex-basis: 50%;
        }
      }

      @media (max-width: $screen-sm-max) {
        gap: 32px;
      }
    }

    .step-summary {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 16px;
      border-radius: 16px;
      background-color: var(--card-bg);

      .row-container {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .title {
          font-size: 12px;
          color: var(--row-title-color);
        }

        .title-image {
          font-size: 14px;
          color: var(--text-primary);
          margin-bottom: 12px;
        }

        .description {
          color: var(--text-primary);
          word-break: break-word;
          white-space: pre-line;

          &--normal {
            font-size: 14px;
          }

          &--small {
            font-size: 12px;
          }
        }

        .group {
          display: flex;
          flex-direction: column;
          gap: 24px;

          .pic {
            margin: 4px 0;
          }
        }

        .pic {
          background-position: center;
          background-size: cover;
          width: 208px;
          height: 208px;
          border-radius: 100px;

          &-square {
            border-radius: 16px;
          }
        }
        .banner {
          background-position: center;
          background-size: cover;
          width: 100%;
          height: 208px;
          border-radius: 6px;
        }
      }
    }

    .buttons-container {
      display: flex;
      justify-content: flex-end;
      gap: 12px;
      width: 100%;

      .prev-btn {
        width: 50%;
      }
      .next-btn {
        width: 50%;
        align-self: flex-end;
      }

      @media (max-width: $screen-sm-max) {
        flex-direction: column-reverse;

        button {
          width: 100% !important;
        }
      }
    }

    .align-self-btn {
      align-self: center;
    }
  }
}
// Form sizes
.form-sm {
  width: 100%;
  max-width: 500px;
}
.form-md {
  width: 100%;
  max-width: 652px;
}
.form-lg {
  width: 100%;
  max-width: 828px;
}

// Tooltip sizes
.tooltip-sm {
}
.tooltip-md {
}
.tooltip-lg {
  width: 100%;
  max-width: 322px;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;
  font-family: "Poppins Medium", sans-serif;
  font-size: 14px;
  // padding-left: 2px;
  cursor: pointer;
  color: var(--text-primary);

  /* Hide the default checkbox */
  input {
    visibility: hidden;
    cursor: pointer;

    /* Show the mark when checked */
    &:checked ~ .mark:after {
      display: block;
    }
  }

  /* Create a custom checkbox */
  .mark {
    position: absolute;
    height: 16px;
    width: 16px;
    background-color: transparent;
    border: 2px solid var(--check-border);
    border-radius: 4px;

    /* Create the mark/indicator (hidden when not checked) */
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 4px;
      top: 0;
      width: 3px;
      height: 7px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  &:hover input ~ .mark {
    //background-color: $orange60;
  }

  input:checked ~ .mark {
    background-color: $orange60;
    border: 2px solid $orange60;
  }

  &--disabled {
    color: var(--checkbox-disabled);
    cursor: auto;

    .mark {
      opacity: .6;
    }
  }
}

// Hide input type number arrows on Mozilla
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

// Dropdown menu
.c-menu-wrapper {
  position: absolute;
  right: 16px;
  top: 50px;
  padding-top: 20px;
  width: 100%;
  z-index: 1;

  .c-menu {
    background: var(--menu-bg);
    color: var(--menu-color);
    border-radius: 8px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    box-shadow: var(--main-shadow);

    &-item {
      font-family: "Poppins SemiBold", sans-serif;
      font-size: $fontSmall;
      border-radius: 8px;
      padding: 12px 16px;
      transition: background-color 350ms;
      margin: 0;
      display: flex;
      align-items: center;
      gap: 6px;

      svg {
        fill: var(--menu-color);
      }

      &:hover {
        background: var(--menu-item-hover);
        cursor: pointer;
      }

      &--disabled {
        color: $greyishBlue60;

        &:hover {
          background: var(--menu-bg);
          cursor: auto;
        }
      }

      &--danger {
        color: $error40;

        svg {
          fill: $error40;
        }
      }
    }
  }
}

.status-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 56px;
  .icon-and-text {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  button {
    white-space: nowrap;
  }
  &--right {
    justify-content: flex-end;
  }
}

.detail-desc {
  color: var(--text-primary);
  font-size: $fontSmall;
  text-align: left;

  &--flex {
    display: flex;
    align-items: center;

    button {
      margin: 0 !important;
    }
  }
}

.tabs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 18px;
  color: var(--tab-color);
  font-weight: $weightSemiBold;
  font-size: $fontSmall;
  border-bottom: 1px solid var(--border-color);
  height: 44px;
  margin: 0 0 24px;
  .tab {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    height: 100%;

    .circle {
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background-color: $orange60;
    }
  }
  .tab-active {
    border-bottom: 3px solid $orange60;
  }
}

[data-theme="light"] {
  --menu-bg: #{$neutral10};
  --menu-color: #{$darkBlue100};
  --menu-item-hover: #{$lightBlue40};
  --checkbox-disabled: #{$greyishBlue30};
  --tab-color: #{$greyishBlue20};
  --border-color: #{$lightBlue50};
}

[data-theme="dark"] {
  --menu-bg: #{$greyishBlue80};
  --menu-color: #{$lightBlue10};
  --menu-item-hover: #{$greyishBlue70};
  --checkbox-disabled: #{$greyishBlue60};
  --tab-color: #{$greyishBlue70};
  --border-color: #{$greyishBlue80};
}

@import "assets/scss/variables";

.cover {
  width: 100%;
  height: 330px;
  border-radius: 24px;

  &-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--banner-bg);

    svg {
      fill: var(--banner-svg);
      width: 35%;
      height: 35%;
    }
  }

  @media (max-width: #{$screen-lg-max}) {
    height: 200px;
  }
}

[data-theme="light"] {
  --banner-bg: #{$lightBlue40};
  --banner-svg: #{$lightBlue70};
}

[data-theme="dark"] {
  --banner-bg: #{$greyishBlue90};
  --banner-svg: #{$greyishBlue70};
}

@import "assets/scss/variables";
@import "assets/scss/fonts";
@import "assets/scss/utils";

.info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  width: 100%;
  position: relative;
  height: 100%;

  .u-title {
    margin: 0 0 4px;
  }

  .u-subtitle {
    min-height: 50px;
  }

  .c-slider-nav {
    display: flex;
    align-items: center;
    gap: 32px;

    .c-dot {
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background-color: var(--dot-bg);
      transition: background-color 350ms;
      &--active {
        background-color: $orange60;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}

@import "assets/scss/variables";
@import "assets/scss/fonts";
@import "assets/scss/utils";
@import "assets/scss/general";

.filter-wrapper {
  position: relative;
  .filter-menu {
    min-width: 256px;
    right: 0;
    top: 48px;
    padding-top: 0;
    .selected {
      background: var(--option-selected);
    }
    .c-menu-item {
      &:hover {
        background-color: var(--option-hover);
      }
    }

    @media (max-width: $screen-lg-max) {
      left: 0;
    }
  }
}

[data-theme="light"] {
  --option-selected: #{$lightBlue40};
  --option-hover: #{$lightBlue30};
}
[data-theme="dark"] {
  --option-selected: #{$greyishBlue60};
  --option-hover: #{$greyishBlue70};
}

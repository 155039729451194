@import "assets/scss/variables";
@import "assets/scss/utils";

.mint-request-popup {
  .title {
    margin: 0 0 8px;
  }

  .content {
    margin: 24px auto;
    text-align: center;
    max-height: 256px;
    overflow-y: scroll;

    .actions-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;
      margin: 12px 0;
    }
  }

  .buttons-container {
    display: flex;
    justify-content: center;
    gap: 12px;
    button {
      width: 50%;
    }
    .btn {
      &-approve {
        background-color: $success50;
        &:hover {
          background-color: $success40;
        }
        &:active {
          background-color: $success30;
        }
      }
      &-reject {
        svg {
          fill: $error50;
        }
        border: 1px solid $error50;
        &:hover {
          border: 1px solid $error50;
          background-color: rgba(253, 43, 94, 0.06);
        }
        &:active {
          border: 1px solid $error50;
          background-color: rgba(253, 43, 94, 0.15);
        }
      }
    }

    @media (max-width: $screen-sm-max) {
      flex-direction: column-reverse;

      button {
        width: 100% !important;
      }
    }
  }
}

@import "assets/scss/variables";
@import "assets/scss/fonts";
@import "assets/scss/utils";

.title-and-msg {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;

  button {
    position: absolute;
    top: 6px;
    left: -12px;
  }
}

@import "assets/scss/variables";
@import "assets/scss/fonts";
@import "assets/scss/utils";

.form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 32px;
  padding: 32px 111px 60px;
  @media (max-width: #{$screen-lg-max}) {
    padding: 24px 24px 48px;
  }

  .form-body {
    display: flex;
    flex-direction: column;
    width: 740px;
    gap: 32px;
    @media (max-width: #{$screen-xxl-max}) {
      width: 70%;
    }
    @media (max-width: #{$screen-lg-max}) {
      width: 100%;
    }
  }

  button[type = "submit"] {
    align-self: flex-end;

    @media (max-width: $screen-sm-max) {
      width: 100%;
    }
  }
}

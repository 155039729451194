@import "assets/scss/variables";
@import "assets/scss/general";
@import "assets/scss/utils";

.course-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 60px;

  .card-pic {
    width: 100%;
    aspect-ratio: 1 / 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 16px;
    }

    &--empty {
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--card-bg);
      border-radius: 16px;

      svg {
        fill: var(--card-svg);
        width: 20%;
        height: 20%;
      }
    }
  }

  .card-labels {
    display: flex;
    gap: 8px;
  }

  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
  }

  .flex-container {
    margin-top: 16px;
    display: flex;
    gap: 8px;
  }

  .details-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .actions-wrapper {
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: flex-start;
    width: 100%;
    gap: 12px;
    background: var(--card-bg);
    border-radius: 12px;
    padding: 14px;
    .btn-group {
      display: flex;
      flex-direction: column;
      gap: 12px;
      button {
        justify-content: flex-start;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    @media (max-width: $screen-sm-max) {
      display: flex;
    }
  }

  .detail-block-wrapper {
    display: flex;
    gap: 12px;
  }

  .detail-block {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;

    button {
      margin-top: 8px;
    }
  }

  .detail-title {
    color: var(--content-color);
    font-size: $fontXSmall;
  }

  .qr-wrapper {
    display: flex;
    align-items: stretch;
    background: var(--card-bg);
    padding: 12px;
    gap: 12px;
    border-radius: 8px;
    flex: none;
    flex-grow: 0;

    .qr-svg {
      border-radius: 4px;
      background: #fff;
      padding: 4px;
      box-shadow: var(--main-shadow);
    }

    .qr-details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 12px;
    }

    .qr-btns-wrapper {
      display: flex;
      gap: 16px;

      > button > div {
        gap: 6px;
      }
      @media (max-width: $screen-sm-max) {
        flex-direction: column;
        button {
          justify-content: flex-start;
        }
      }
    }

    @media (max-width: #{$screen-sm-max}) {
      flex-direction: column;
    }
  }

  @media (max-width: #{$screen-lg-max}) {
    flex-direction: column;
    gap: 24px;
  }

  @media (max-width: #{$screen-sm-max}) {
    .detail-block-wrapper {
      flex-direction: column;
    }
  }

  @media print {
    display: none;
  }
}

.students-wrapper {
  margin: 48px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .mobile-btns {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: -24px;

    &--main {
      display: flex;
      gap: 6px;

      > div {
        flex: 1 1;

        button {
          width: 100%;
        }
      }
    }
    .u-align-self--end {
      margin: 0 0 0 auto;
    }
  }

  @media print {
    display: none;
  }
}

//Style QR print page
.print-wrapper {
  display: none;
  margin-top: 100px;
  text-align: center;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  .qr-svg {
    border-radius: 16px;
    background: #fff;
    padding: 12px;
  }

  @media print {
    display: flex;
  }
}

@import "assets/scss/variables";
@import "assets/scss/general";
@import "assets/scss/utils";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  margin: 88px 0;

  img {
    width: 256px;
    height: 256px;
  }

  &:global(.wrapper-modal) {
    margin: 24px 0;

    img {
      width: 128px;
      height: 128px;
    }
  }
}

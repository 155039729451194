@import "assets/scss/variables";
@import "assets/scss/utils";

.qr-popup {
  .title {
    margin: 0 0 8px;
  }

  .content {
    margin: 24px auto;
    text-align: center;

    .qr-svg {
      border-radius: 16px;
      background: #fff;
      padding: 12px;
      box-shadow: var(--main-shadow);

      @media (max-width: $screen-sm-max) {
        width: 100%;
        height: auto;
      }
    }
  }

  .buttons-container {
    display: flex;
    justify-content: center;
  }
}

@import "assets/scss/variables";
@import "assets/scss/general";
@import "assets/scss/utils";

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 480px;
  width: var(--dnd-area-max-width);
  gap: 12px;
  color: var(--content-color);
  font-size: $fontSmall;
  outline: var(--dnd-area-border);
  border-radius: 8px;
  position: relative;

  input {
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;
  }

  // @media (max-width: $screen-xl-max) {
  //   height: 225px;
  //   width: 300px;
  // }
}
.highlight {
  border: 2px solid $orange60;
}

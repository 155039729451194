@import "assets/scss/general";
@import "assets/scss/variables";
@import "assets/scss/utils";

* {
  scroll-behavior: smooth;
}

.wrapper {
  background-color: var(--background);
  width: 100%;
  height: 100%;
  min-height: 100vh;
  max-height: 100vh;
  overflow: auto;
  position: relative;
  transition: background-color 350ms;

  .logo {
    cursor: pointer;
  }

  .header {
    position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--hero-bg);
    backdrop-filter: blur(5px);
    z-index: 1;
    transition: background-color 350ms;

    &--scrolled {
      background-color: var(--header-bg);
    }

    @media (max-width: $screen-lg-max) {
      flex-direction: column;
    }

    .logo-and-menu {
      padding: 24px;
      display: flex;
      @media (max-width: $screen-lg-max) {
        padding: 16px 24px;
        width: 100%;
        justify-content: space-between;
      }
    }

    .main-menu {
      display: flex;
      align-items: center;
    }

    .main-menu > li {
      position: relative;
      display: flex;
      align-items: center;
      float: left;
      margin: 0 12px;
      color: var(--text-primary);
      font-size: $fontSmall;
      font-family: "Poppins SemiBold", sans-serif;
      cursor: pointer;
      transition: color 350ms;

      &:hover {
        color: var(--menu-hover);
      }

      &:active {
        color: var(--menu-active);
      }
    }

    .actions {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 24px;
      > a {
        margin: 0;
      }
    }

    .mobile-menu-btn {
      svg {
        width: 32px;
        height: 32px;
      }
    }

    .mobile-menu-wrapper {
      display: flex;
      position: relative;
      width: 100%;
      max-height: 0;
      overflow: hidden;
      border-radius: 0 0 16px 16px;
      transition: max-height 350ms;
      .main-menu {
        display: flex;
        flex-direction: column;
        gap: 8px;
        li {
          width: 100%;
          margin: 0;
        }
        a {
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 56px;
          width: 100%;
        }
      }
      .actions {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
        gap: 16px;
        padding: 0 24px 16px 24px;
        .link-to-app {
          width: 100%;
        }
        .main-menu {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
        }
        .theme-btn {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          height: 56px;
          padding: 16px;
        }
      }
    }
    .open {
      max-height: 100vh;
      overflow-x: auto;
      transition: max-height 350ms;
    }
  }
  .rounded-bottom-border {
    border-radius: 0 0 16px 16px;
  }

  .container {
    width: 1280px;
    margin: 0 auto;

    @media (max-width: 1920px) {
      width: 1064px;
    }

    @media (max-width: 1100px) {
      padding: 0 24px;
      width: 100%;
    }
  }

  .container--sm {
    width: 850px;
    margin: 0 auto;

    @media (max-width: 900px) {
      padding: 0 24px;
      width: 100%;
    }

    @media (max-width: $screen-sm-max) {
      > div {
        width: 100%;
      }
    }
  }

  .accent {
    word-break: break-word;
    background: -webkit-linear-gradient(45deg, #ff7715, #ffcda6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .hero {
    padding: 180px 0 280px;
    width: 100%;
    background-color: var(--hero-bg);
    border-bottom-left-radius: 72px;
    border-bottom-right-radius: 72px;
    transition: background-color 350ms;

    .container--sm {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 12px;
    }

    .title {
      margin: 0;
      font-family: "Poppins SemiBold", sans-serif;
      font-size: $fontH2;
      line-height: 62px;
      text-align: center;

      @media (max-width: $screen-md-max) {
        font-size: $fontH4;
        line-height: 32px;
      }
    }

    p {
      margin: 0;
    }

    @media (max-width: $screen-md-max) {
      padding: 140px 0 280px;
      border-bottom-left-radius: 24px;
      border-bottom-right-radius: 24px;
    }
  }

  .screenshot {
    padding: 56px 0;
    margin-top: -240px;
    &__img {
      width: 100%;
      height: auto;
      border-radius: 16px;
      box-shadow: var(--screenshot-box-shadow);

      @media (max-width: $screen-sm-max) {
        border-radius: 6px;
      }
    }
  }

  .partners-section {
    padding: 56px 0;

    .partners {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      gap: 32px;

      &__group {
        display: flex;
        flex-direction: column;
        gap: 42px;
        flex: 1;

        @media (max-width: $screen-lg-max) {
          text-align: center;
        }
      }

      &__logos {
        display: flex;
        flex-wrap: wrap;
        gap: 32px;

        a {
          margin: 0;
          height: 48px;
          display: flex;
          align-items: center;

          img {
            filter: invert(47%) sepia(26%) saturate(514%) hue-rotate(196deg)
              brightness(92%) contrast(88%);
            transition: filter 350ms;

            &:hover {
              filter: none;
            }
          }
        }

        @media (max-width: $screen-lg-max) {
          justify-content: center;
        }
      }

      .divider {
        background-color: var(--divider-bg);
        width: 1px;

        @media (max-width: $screen-lg-max) {
          width: 100%;
          height: 1px;
        }
      }

      @media (max-width: $screen-lg-max) {
        flex-direction: column;
        align-items: center;
      }
    }
  }

  .futureproof-section {
    padding: 100px 0;

    .title {
      margin: 0;
      font-family: "Poppins Bold", sans-serif;
      font-size: $fontH4;
      line-height: 32px;
      text-align: center;
      border: 1px solid var(--divider-bg);
      border-radius: 16px;
      padding: 64px 24px;

      @media (max-width: $screen-sm-max) {
        font-size: $fontLarge;
        line-height: 24px;
        padding: 40px 24px;
      }
    }

    @media (max-width: $screen-md-max) {
      padding: 56px 0;
    }
  }

  .benefits-section {
    padding: 100px 0;

    .title {
      margin: 0;
      font-family: "Poppins Bold", sans-serif;
      font-size: $fontH3;
      line-height: 48px;
      text-align: center;

      @media (max-width: $screen-md-max) {
        font-size: $fontH4;
        line-height: 32px;
        margin-bottom: 24px;
      }
    }

    .tabs {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 24px;

      .tab {
        padding: 12px;
        color: var(--tab-color);
        font-family: "Poppins Medium", sans-serif;
        font-size: $fontLarge;
        line-height: 24px;
        border-bottom: 3px solid transparent;
        text-align: center;
        cursor: pointer;
        transition: border 350ms, color 350ms;

        &--active {
          color: var(--text-primary);
          border-bottom: 3px solid $orange60;
        }

        @media (max-width: $screen-md-max) {
          flex: 1;
        }
      }
    }
    .tab-items {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 120px;
      margin: 80px 0;

      .item {
        display: flex;
        align-items: center;
        gap: 48px;

        &__text {
          flex-basis: 50%;
          display: flex;
          flex-direction: column;
          gap: 12px;
          padding: 80px;
        }

        &__title {
          font-family: "Poppins Bold", sans-serif;
          font-size: $fontLarge;
          color: var(--text-primary);
          margin: 0;
        }

        p {
          margin: 0;
          font-size: $fontSmall;
          color: var(--content-color);
        }

        &__img {
          flex-basis: 50%;
          text-align: center;
          img {
            margin: 0 auto;
            width: 70%;
          }
        }

        @media (max-width: $screen-md-max) {
          flex-direction: column;
          gap: 24px;

          &--reverse {
            flex-direction: column-reverse;
          }

          &__text {
            padding: 0;
          }

          &__img {
            img {
              width: 100%;
            }
          }
        }
      }

      @media (max-width: $screen-md-max) {
        margin: 48px 0 0;
        gap: 56px;
      }
    }

    @media (max-width: $screen-md-max) {
      padding: 66px 0;
    }
  }

  .onboarding-section {
    padding: 100px 0;
    background: var(--hero-bg);

    .title {
      margin: 0;
      font-family: "Poppins Bold", sans-serif;
      font-size: $fontH3;
      line-height: 48px;
      text-align: center;

      @media (max-width: $screen-md-max) {
        font-size: $fontH4;
        line-height: 32px;
        margin-bottom: 24px;
      }
    }

    .tabs {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 24px;

      .tab {
        padding: 12px;
        color: var(--tab-color);
        font-family: "Poppins Medium", sans-serif;
        font-size: $fontLarge;
        line-height: 24px;
        border-bottom: 3px solid transparent;
        text-align: center;
        cursor: pointer;
        transition: border 350ms, color 350ms;

        &--active {
          color: var(--text-primary);
          border-bottom: 3px solid $orange60;
        }

        @media (max-width: $screen-md-max) {
          flex: 1;
        }
      }
    }
    .tab-items {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 120px;
      margin: 80px 0;

      .item {
        display: flex;
        align-items: center;
        gap: 48px;

        &__text {
          flex-basis: 50%;
          display: flex;
          flex-direction: column;
          gap: 12px;
          padding: 80px;
        }

        &__title {
          font-family: "Poppins Bold", sans-serif;
          font-size: $fontLarge;
          color: var(--text-primary);
          margin: 0;
        }

        p {
          margin: 0;
          font-size: $fontSmall;
          color: var(--content-color);
        }

        &__img {
          flex-basis: 50%;
          text-align: center;
          img {
            margin: 0 auto;
            width: 70%;
          }
        }

        @media (max-width: $screen-md-max) {
          flex-direction: column;
          gap: 24px;

          &--reverse {
            flex-direction: column-reverse;
          }

          &__text {
            padding: 0;
          }

          &__img {
            img {
              width: 100%;
            }
          }
        }
      }

      @media (max-width: $screen-md-max) {
        margin: 48px 0 0;
        gap: 56px;
      }
    }

    .accordion-wrapper {
      display: flex;
      align-items: center;
      gap: 48px;
      width: 100%;

      > div {
        flex: 1 1;
      }

      .accordion-section {
        padding: 0;
        display: flex;
        flex-direction: column;

        .accordion__q {
          display: flex;
          justify-content: space-between;

          > div {
            display: flex;
            gap: 24px;
          }

          &__number {
            background: var(--number-bg);
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            flex-shrink: 0;

            &--active {
              background: $orange60;
              color: #fff;
            }
          }
        }

        .accordion__a {
          margin: 0;
          padding-left: 36px;
          border-left: 1px solid var(--divider-bg);
          max-height: 0;
          overflow: hidden;
          transition: all 350ms;
        }

        .open {
          max-height: 200px;
          margin: 0 0 24px 16px;
          overflow: auto;
          transition: all 350ms;
        }

        > button {
          margin-top: 24px;
        }
      }

      .accordion-item__img {
        img {
          width: 100%;
        }
      }

      @media (max-width: $screen-md-max) {
        flex-direction: column;
      }
    }

    @media (max-width: $screen-md-max) {
      padding: 66px 0;
    }
  }

  .ready-section {
    padding: 100px 0 56px;

    .box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 12px;
      border-radius: 16px;
      padding: 88px 24px;
      background: var(--spot-blue) no-repeat -600px -1050px,
        var(--spot-orange) no-repeat 700px -50px;
      box-shadow: var(--ready-box-shadow);
      position: relative;
      overflow: hidden;

      @media (max-width: $screen-lg-max) {
        background: var(--spot-blue) no-repeat -800px -1050px,
          var(--spot-orange) no-repeat 200px -50px;
      }

      @media (max-width: $screen-sm-max) {
        background: var(--spot-blue) no-repeat -800px -1150px,
          var(--spot-orange) no-repeat 80px -100px;
        padding: 24px;
      }
    }

    .title {
      margin: 0;
      font-family: "Poppins SemiBold", sans-serif;
      font-size: $fontH3;
      line-height: 48px;
      text-align: center;

      @media (max-width: $screen-md-max) {
        font-size: $fontH4;
        line-height: 32px;
      }
    }

    p {
      margin: 0;
    }
  }

  .features-section {
    padding: 160px 0;

    .title {
      margin: 0;
      font-family: "Poppins Bold", sans-serif;
      font-size: $fontH3;
      line-height: 48px;

      @media (max-width: $screen-md-max) {
        font-size: $fontH4;
        line-height: 32px;
        text-align: center;
        margin-bottom: 24px;
      }
    }

    .features-group {
      display: flex;
      justify-content: space-between;
      gap: 56px;

      .features-column {
        flex: 1;
        padding: 56px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 56px;

        .feature {
          display: flex;
          align-items: center;
          gap: 24px;

          &__text {
            display: flex;
            flex-direction: column;
            gap: 12px;
          }

          &__title {
            font-family: "Poppins Bold", sans-serif;
            font-size: $fontLarge;
            color: var(--text-primary);
            margin: 0;
          }

          p {
            margin: 0;
            font-size: $fontSmall;
            color: var(--content-color);
          }
        }
      }

      @media (max-width: $screen-lg-max) {
        gap: 32px;

        .features-column {
          padding: 32px;
          gap: 32px;
        }
      }

      @media (max-width: $screen-md-max) {
        flex-direction: column;
        gap: 0;

        .features-column {
          padding: 16px;
        }
      }
    }

    @media (max-width: $screen-md-max) {
      padding: 66px 0;
    }
  }

  .accordion-section {
    padding: 160px 0;
    background-color: var(--hero-bg);
    border-top-left-radius: 72px;
    border-top-right-radius: 72px;

    .title {
      margin: 0;
      font-family: "Poppins Bold", sans-serif;
      font-size: $fontH3;
      line-height: 48px;
      text-align: center;

      @media (max-width: $screen-md-max) {
        font-size: $fontH4;
        line-height: 32px;
      }
    }

    .accordions {
      margin-top: 80px;
      display: flex;
      flex-direction: column;
    }

    .accordion__el {
      display: flex;
      flex-direction: column;
    }

    .accordion {
      &__q {
        display: flex;
        align-items: center;
        gap: 4px;
        margin: 24px 0;
        cursor: pointer;

        > button {
          flex-shrink: 0;
        }

        .chevron {
          &--active {
            svg {
              fill: $orange60;
            }
          }
        }

        h2 {
          font-family: "Poppins Bold", sans-serif;
          font-size: $fontLarge;
          color: var(--text-primary);
          margin: 0;
        }
      }

      &__a {
        font-size: $fontSmall;
        color: var(--content-color);
        margin: 0 0 0 44px;
        margin-bottom: 0;
        max-height: 0;
        overflow: hidden;
        transition: max-height 350ms, margin-bottom 350ms;
      }
    }

    .open {
      max-height: 400px;
      margin-bottom: 24px;
      transition: max-height 350ms;
    }

    @media (max-width: $screen-md-max) {
      padding: 66px 0;
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;

      .accordions {
        margin-top: 40px;
      }
    }
  }

  .contacts-section {
    padding: 66px 0;
    background-color: var(--hero-bg);

    .contacts-wrapper {
      display: flex;
      align-items: center;
      gap: 64px;

      @media (max-width: $screen-md-max) {
        flex-direction: column-reverse;
        gap: 36px;
      }
    }

    .title {
      margin: 0;
      font-family: "Poppins Bold", sans-serif;
      font-size: $fontH3;
      line-height: 48px;

      @media (max-width: $screen-md-max) {
        font-size: $fontH4;
        line-height: 32px;
        margin-bottom: 24px;
        text-align: center;
        display: none;
      }

      &--mobile {
        display: none;
        margin-bottom: 48px;

        @media (max-width: $screen-md-max) {
          display: block;
        }
      }
    }

    .contacts {
      flex: 1 1 0;
      display: flex;
      flex-direction: column;
      gap: 48px;

      .contact-group {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 16px;

        &__title {
          font-family: "Poppins Bold", sans-serif;
          font-size: $fontLarge;
          color: var(--text-primary);
          margin: 0 0 8px;
        }

        p {
          margin: 0;
          font-size: $fontSmall;
          color: var(--content-color);
        }

        a {
          color: var(--link);
          font-family: "Poppins Bold", sans-serif;
          text-decoration: underline;
          margin: 0;
        }
      }
    }

    .form-wrapper {
      flex: 1.5 1 0;
      padding: 48px;
      background-color: var(--background);
      border-radius: 16px;

      &__title {
        font-family: "Poppins Bold", sans-serif;
        font-size: $fontLarge;
        color: var(--text-primary);
        margin: 0;
      }

      p {
        font-size: $fontSmall;
        color: var(--text-primary);
        margin: 8px 0 24px;
      }

      .form {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .input-group-half {
          display: flex;
          gap: 8px;

          > div {
            &:nth-child(1) {
              flex-basis: 50%;
            }

            &:nth-child(2) {
              flex-basis: 50%;
            }
          }

          > button {
            flex-basis: 50%;
          }

          @media (max-width: $screen-sm-max) {
            flex-direction: column;
          }
        }

        textarea {
          min-height: 96px;
        }
      }

      @media (max-width: $screen-md-max) {
        padding: 24px;
      }
    }
  }

  .divider-section {
    padding: 56px 0 0;
    background-color: var(--hero-bg);

    @media (max-width: $screen-md-max) {
      padding: 16px 0 0;
    }
  }

  .btn-container {
    display: flex;
    gap: 24px;
    margin-top: 36px;

    a {
      margin: 0;
    }

    @media (max-width: $screen-sm-max) {
      flex-direction: column;
      gap: 8px;
      width: 100%;

      button {
        width: 100%;
      }
    }
  }

  .footer {
    background-color: var(--hero-bg);
    padding: 70px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--left {
      display: flex;
      align-items: center;
      gap: 48px;
    }

    &__menu li {
      float: left;
      margin: 0 8px;
      color: var(--text-primary);
      font-size: $fontSmall;
      font-family: "Poppins Regular", sans-serif;
      cursor: pointer;

      &:hover {
        color: var(--menu-hover);
      }

      &:active {
        color: var(--menu-active);
      }
    }

    &__social {
      display: flex;
      align-items: center;
      gap: 24px;

      button {
        width: 24px;
      }
    }

    &--right {
      display: flex;
      align-items: center;
      gap: 48px;
    }

    @media (max-width: $screen-md-max) {
      flex-direction: column;
      align-items: start;

      &--left {
        flex-direction: column;
        align-items: start;
      }

      &__menu li {
        float: none;
        margin: 0 0 16px;

        &:last-child {
          margin: 0;
        }
      }
    }
  }
}

[data-theme="light"] {
  --hero-bg: #{$lightBlue30};
  --menu-hover: #{$greyishBlue50};
  --menu-active: #{$greyishBlue30};
  --spot-blue: url("../../assets/landing/spot-blue-light.png");
  --spot-orange: url("../../assets/landing/spot-orange-light.png");
  --ready-box-shadow: 0px 0px 32px rgba(8, 9, 24, 0.1);
  --screenshot-box-shadow: 0px 0px 100px rgba(8, 9, 24, 0.12);
  --link: #{$darkBlue10};
  --tab-color: #{$greyishBlue20};
  --number-bg: #{$neutral10};
}

[data-theme="dark"] {
  --hero-bg: #{$darkBlue100};
  --menu-hover: #{$greyishBlue30};
  --menu-active: #{$greyishBlue50};
  --spot-blue: url("../../assets/landing/spot-blue-dark.png");
  --spot-orange: url("../../assets/landing/spot-orange-dark.png");
  --ready-box-shadow: 0px 0px 60px rgba(8, 9, 24, 0.5);
  --screenshot-box-shadow: 0px 0px 120px #080918;
  --link: #{$lightBlue100};
  --tab-color: #{$greyishBlue70};
  --number-bg: #{$greyishBlue20};
}

@import "assets/scss/variables";
@import "assets/scss/utils";
@import "assets/scss/general";

.custom-option {
  display: flex;
  align-items: center;
  gap: 12px;
}

.simple-label {
  text-align: left;
  font-size: 14px;
  color: var(--no-options-color);
}

[data-theme="light"] {
  --no-options-color: #{$greyishBlue10};
}

[data-theme="dark"] {
  --no-options-color: #{$greyishBlue70};
}
.only-chev {
  display: flex;
  align-items: center;
  max-width: 16px;
}

@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/general";
@import "../../../../assets/scss/utils";

.card {
  position: relative;
  flex: 0 1 calc(25% - 12px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  border-radius: 16px;
  padding: 48px 16px;
  background: var(--card-bg);
  cursor: default;
  transition: background-color 0.2s;

  &--clickable {
    cursor: pointer;

    &:hover {
      background: var(--card-bg-hover);
    }

    &:active {
      background: var(--card-bg-active);
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  h6 {
    color: var(--text-primary);
    font-size: 18px;
  }

  @media (max-width: #{$screen-xl-max}) {
    flex: 0 1 calc(50% - 12px);
  }

  @media (max-width: #{$screen-sm-max}) {
    flex: 0 1 calc(100% - 12px);
    flex-direction: column;

    > div {
      text-align: center;
    }
  }
}


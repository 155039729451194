@import "assets/scss/variables";
@import "assets/scss/utils";

.create-course-popup {
  min-height: 460px;

  .options-container {
    display: flex;
    gap: 16px;

    .options {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      border-radius: 16px;
      background-color: var(--card-bg);
      padding: 40px 24px;
      text-align: center;
      height: 375px;
      cursor: pointer;
      transition: background-color 350ms;

      &:hover {
        background-color: var(--card-bg-hover);
      }
    }

    @media (max-width: #{$screen-sm-max}) {
      flex-direction: column;
    }
  }

  .content-wrapper {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 32px;

    .templates-container {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      padding-right: 16px;
      max-height: 350px;
      overflow-y: scroll;
    }

    .buttons-container {
      display: flex;
      gap: 24px;

      > button {
        flex: 1 1;
      }

      @media (max-width: $screen-sm-max) {
        flex-direction: column-reverse;
        gap: 12px;
      }
    }
  }
}

@import "assets/scss/variables";
@import "assets/scss/mixins";

.main-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: $fontSmall;
  margin-bottom: 8px;

  &--flex-row {
    flex-direction: row;
    align-items: center;
    gap: 12px;

    .d-flex {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    @media (max-width: $screen-sm-max) {
      flex-direction: column-reverse;
    }
  }

  .title {
    font-family: "Poppins", sans-serif;
    color: var(--text-primary);
  }
  .subtitle {
    color: $greyishBlue50;
    margin-bottom: 8px;
  }
  .input-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 208px;
    height: 208px;
    border-radius: 50%;
    position: relative;
    opacity: 1;
    box-shadow: var(--image-shadow);

    transition: opacity 350ms;
    img {
      position: absolute;
      border-radius: 50%;
    }
    .custom-file-upload {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 208px;
      height: 208px;
      border: 1px dashed transparent;
      border-radius: 50%;
      background-color: transparent;
      cursor: pointer;
      z-index: 1;

      .hidden-input {
        opacity: 0;
      }

      .instructions {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
      }

      .actions {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        background: var(--actions-bg);
        padding-top: 30px;
        opacity: 0;
        transition: var(--transition);

        @media (max-width: #{$screen-xl-max}) {
          padding-top: 0;
          opacity: 1;
        }

        svg {
          fill: var(--actions-svg-color);
        }

      }

      .actions-full-width {
        border-radius: 12px;
      }

      &:hover {
        box-shadow: inset 0 0 0 1px var(--actions-bg);
        .actions {
          padding-top: 0;
          opacity: 1;
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      &--empty {
        border: 1px dashed $greyishBlue70;
        &:hover {
          border: 1px dashed $greyishBlue70;
        }
      }
    }
    input[type="file"] {
      display: none;
    }
    .image-comp {
      border-radius: 50%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      width: 100%;
      height: 100%;
    }
    .signature {
      background-size: contain;
      border: 1px dashed var(--signature-outline-col);
    }
    &.square {
      border-radius: 16px;

      img {
        border-radius: 16px;
      }

      .custom-file-upload {
        border-radius: 16px;
      }

      .actions {
        border-radius: 16px;
      }

      .image-comp {
        border-radius: 16px;
      }
    }
  }
  .full-width {
    width: 100% !important;
    border-radius: 12px !important;
  }
}

[data-theme="light"] {
  --actions-bg: rgba(245, 247, 255, 0.85);
  --actions-svg-color: #{$greyishBlue50};
  --signature-outline-col: #{$greyishBlue50};
}

[data-theme="dark"] {
  --actions-bg: rgba(36, 38, 58, 0.9);
  --actions-svg-color: #{$greyishBlue30};
  --signature-outline-col: #{$greyishBlue70};
}

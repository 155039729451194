@import "assets/scss/variables";
@import "assets/scss/fonts";
@import "assets/scss/utils";
@import "assets/scss/general";

.header-checkbox-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  width: 16px;
  height: 16px;
  gap: 4px;
}

.checkbox {
  display: flex;
  width: 16px;
  height: 16px;
  input,
  span {
    margin: 0;
  }
  &:hover {
    span {
      border: 2px solid var(--checkbox-border-hover-col);
    }
  }
}
.status-minted,
.status-minted-in-course {
  width: 20px;
  height: 20px;

  input,
  span {
    display: none;
  }

  svg {
    fill: $success40;
  }
}
.status-rejected {
  width: 20px;
  height: 20px;

  input,
  span {
    display: none;
  }

  svg {
    fill: $error50;
  }
}

.select-dropdown {
  left: -38px !important;
  top: calc(100% + 4px) !important;
}

.names-container {
  display: flex;
  align-items: center;
  gap: 16px;
  word-break: break-word;

  .pic {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: cover;
  }

  .course-card {
    flex-basis: unset;
    width: 80px;
    height: 80px;
  }
}

.pic-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background-color: var(--pic-bg);
  border-radius: 50%;
}

.email-container {
  word-break: break-word;
}

.actions-container {
  display: flex;
  justify-content: flex-end;
  gap: 12px;

  @media (max-width: #{$screen-sm-max}) {
    flex-direction: column;
  }
}

.flex-container {
  display: flex;
  gap: 6px;
}

.c-menu-wrapper {
  right: 16px;
  width: 256px;
  top: 12px;
}

.actions-container {
  display: flex;
  justify-content: flex-end;
  gap: 12px;

  &--clean {
    @media (max-width: #{$screen-sm-max}) {
      .c-menu-wrapper {
        left: 50%;
        margin-left: -128px;
      }
    }
  }

  @media (max-width: #{$screen-sm-max}) {
    flex-direction: column;
  }
}

.diploma-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .thumbnail-and-title {
    display: flex;
    align-items: center;
    gap: 24px;
    img {
      object-fit: contain;
    }
  }

  button {
    margin-left: auto;
  }
}

.approved {
  color: $success50;
  text-align: right;
}

.rejected {
  color: $error50;
  text-align: right;
}

.mobile-container {
  display: flex;
  align-items: center;
  gap: 16px;
  word-break: break-word;

  .u-flex {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  &--column {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  @media (max-width: #{$screen-xs-max}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
}

.school-course-mobile {
  display: flex;
  align-items: center;
  gap: 16px;
  .school-course-card {
    width: 80px;
    height: 80px;
    flex-grow: unset !important;
    flex-shrink: unset !important;
    flex-basis: unset !important;
  }
}

[data-theme="light"] {
  --pic-bg: #{$lightBlue50};
  --select-dropdown-bg: #{$lightBlue30};
  --checkbox-border-hover-col: #{$darkBlue100};
}
[data-theme="dark"] {
  --pic-bg: #{$greyishBlue80};
  --select-dropdown-bg: #{$greyishBlue80};
  --checkbox-border-hover-col: #{$lightBlue10};
}

@import "assets/scss/variables";
@import "assets/scss/fonts";
@import "assets/scss/utils";
@import "assets/scss/general";

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 32px;
  align-self: center;
  padding: 140px 0 60px;

  .title {
    font-family: "Poppins Bold", sans-serif;
    color: var(--text-primary);
    text-align: center;
    margin: 0;
    font-size: $fontH3;
  }
  .body {
    display: flex;
    flex-direction: column;
    margin: 0;
    gap: 32px;
    width: 100%;
    button {
      width: 50%;
      align-self: flex-end;
      @media (max-width: $screen-sm-max) {
        width: 100%;
      }
    }
  }
}

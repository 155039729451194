@import "assets/scss/variables";
@import "assets/scss/general";
@import "assets/scss/utils";

.box-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;

  .box {
    position: absolute;
    cursor: move;
    white-space: nowrap;
  }

  .text-box {
    display: flex;
    align-items: center;
    padding: 0;
    outline: 1px dashed $greyishBlue40;
    width: calc(100% + 24px);
    position: relative;
    line-height: 1;
  }

  .instructor-name {
    overflow-wrap: break-word;
    width: 150px;
  }

  .logo-box {
    display: flex;
    cursor: move;
    white-space: nowrap;
    .logo {
      position: relative !important;
      max-width: 240px;
      object-fit: cover;
    }
  }

  .no-logo {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    outline: 1px dashed $greyishBlue40;
    color: $greyishBlue40;
    svg {
      fill: $greyishBlue40;
    }
  }

  .signature-box {
    display: flex;
    cursor: move;
    white-space: nowrap;
    padding: 0;
    .singature {
      position: relative !important;
      max-width: 240px;
      object-fit: cover;
    }
  }

  .selected {
    background-color: rgba($greyishBlue20, 0.1);
    outline: 1px solid $greyishBlue40;
  }
}

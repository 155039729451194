@import "assets/scss/variables";
@import "assets/scss/utils";
@import "assets/scss/general";

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: var(--bg);
  background-image: var(--bg-img);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: background-color 350ms;

  .switcher {
    position: absolute;
    bottom: 24px;
    left: 24px;

    @media (max-width: $screen-lg-max) {
      display: none;
    }
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 48px;
    width: 100%;
    height: 100%;
    padding: 105px 24px 24px;

    .description {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 8px;
      text-align: center;

      .u-title {
        font-size: $fontH1;

        @media (max-width: #{$screen-md-max}) {
          font-size: $fontH3;
        }
      }
    }
    button {
      max-width: 322px;
      width: 100%;
    }

    @media (max-width: #{$screen-md-max}) {
      gap: 24px;
      padding: 24px;
    }
  }
}

[data-theme="light"] {
  --bg: #{$lightBlue30};
  --bg-img: url("../../assets/images/404-page/shapes-light.png");
}
[data-theme="dark"] {
  --bg: #{$darkBlue100};
  --bg-img: url("../../assets/images/404-page/shapes-dark.png");
}

@import "assets/scss/variables";
@import "assets/scss/fonts";

.wrapper {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: var(--main-content-bg);

  .main-grid {
    height: 100%;
    min-height: 100vh;
    display: grid;
    grid-template-columns: var(--sidebar-width) auto;
    transition: grid-template-columns 350ms;

    .col-right {
      flex: 1 0 100%;
      background-color: var(--main-content-bg);
      transition: background-color 350ms;
      position: relative;
      width: 100%;
      overflow-y: scroll;

      @media (max-width: #{$screen-lg-max}) {
        display: flex;
        flex-direction: column;
      }

      @media (max-width: $screen-sm-max) {
        width: 100%;
      }

      .children-components {
        height: 100%;
        padding-top: 105px;
        overflow: auto;

        @media (max-width: #{$screen-lg-max}) {
          padding-top: 0;
        }
      }
    }
    @media (max-width: $screen-lg-max) {
      display: flex;
      grid-template-columns: unset;
    }
  }
}

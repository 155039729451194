@import "assets/scss/variables";
@import "assets/scss/utils";
@import "assets/scss/general";

.label-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 4px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 8px;
  font-size: $fontSmall;

  &--no-margin {
    margin-bottom: 0;
  }

  .title-container {
    display: flex;
    align-items: center;
    color: var(--text-primary);
  }

  a {
    display: flex;
    margin: 0;

    .info-icon {
      fill: var(--icon-fill);
    }
  }

  .subtitle {
    color: $greyishBlue50;
  }
}

[data-theme="light"] {
  --icon-fill: #{$greyishBlue30};
}
[data-theme="dark"] {
  --icon-fill: #{$greyishBlue50};
}

@import "assets/scss/variables";
@import "assets/scss/fonts";
@import "assets/scss/utils";
@import "assets/scss/general";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 32px;
  margin: 0 auto;
  padding: 200px 0 60px;

  .flex-subcontainer {
    display: flex;
    gap: 24px;

     > div {
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;
       width: 100%;
       gap: 24px;
       margin: 0 auto;
     }
  }

  @media (max-width: $screen-lg-max) {
    padding: 100px 0 60px;

    .flex-subcontainer {
      flex-direction: column;
      align-items: center;
    }
  }
}

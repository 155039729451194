@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/general";
@import "../../../../assets/scss/utils";

.cards-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 48px;
}

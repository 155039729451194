@import "assets/scss/variables";
@import "assets/scss/general";
@import "assets/scss/utils";

.content-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 32px 32px 60px;
  gap: 140px;

  @media (max-width: $screen-xl-max) {
    flex-direction: column;
    align-items: center;
    gap: 64px;
  }
  .dnd-container {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    .dnd-active {
      display: flex;
      flex-direction: column;
      gap: 24px;
      .dnd {
        position: relative;
        width: var(--dnd-area-max-width);
        display: flex;
        justify-content: center;
        align-items: center;
        outline: var(--dnd-area-border);
        border-radius: 8px;
        overflow: hidden;
        .bg-img {
          position: absolute;
          width: 100%;
        }
      }
      .highlight {
        outline: 2px solid $orange60;
      }
      .button-groups {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .align-buttons {
          display: flex;
          align-items: center;
          gap: 4px;
          button {
            width: 40px;
            height: 40px;
          }
        }
      }
      .preview-container {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
        .inputs-container {
          display: flex;
          align-items: center;
          gap: 16px;
        }
      }
      @media (max-width: $screen-xl-max) {
        align-items: center;
      }
    }
  }

  .fields-area {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    width: 100%;
    max-width: 400px;

    @media (max-width: $screen-xl-max) {
      max-width: 640px;
    }

    .title {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: flex-start;
    }
  }
}

[data-theme="light"] {
  --dnd-area-border: 1px solid #{$lightBlue50};
  --dnd-area-max-width: 640px;
}
[data-theme="dark"] {
  --dnd-area-border: 1px solid #{$greyishBlue70};
  --dnd-area-max-width: 640px;
}

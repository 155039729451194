@import "assets/scss/variables";
@import "assets/scss/utils";
@import "assets/scss/general";

.label-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.subtitle {
  color: $greyishBlue50;
  margin-bottom: 16px;
  font-size: $fontSmall;
}

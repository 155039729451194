@import "assets/scss/variables";
@import "assets/scss/fonts";
@import "assets/scss/utils";

.c-sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  background-color: var(--sidebar-bg);
  width: var(--sidebar-width);
  z-index: 11;
  transition: background-color 350ms, width 350ms;

  @media (max-width: $screen-lg-max) {
    display: none;
  }

  .tabs-container {
    position: fixed;
    width: calc(var(--sidebar-width) - 48px);
    transition: width 350ms;
  }

  .c-btn--collapse {
    position: fixed;
    left: calc(var(--sidebar-width) - 16px);
    top: 36px;
    opacity: 0;
    z-index: 11;
    background-color: var(--btn-collapse-bg);
    transition: left 350ms;
    @media (max-width: #{$screen-xl-max}) {
      opacity: 1;
    }
  }

  &:hover {
    .c-btn--collapse {
      opacity: 1;
    }
  }

  .c-logo {
    margin-bottom: 32px;
    cursor: pointer;
  }

  .c-items-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;

    &--fixed {
      width: calc(var(--sidebar-width) - 48px);
      position: fixed;
      bottom: 24px;
    }

    .c-item {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 16px;
      gap: 12px;
      margin-bottom: 8px;
      border-radius: 8px;
      color: var(--text-primary);
      font-family: "Poppins SemiBold", sans-serif;
      font-size: $fontSmall;

      .icon {
        width: 24px;
        height: 24px;
        svg {
          fill: var(--text-primary);
          width: 24px;
          height: 24px;
        }
      }
      .title {
        white-space: nowrap;
      }

      &:hover {
        background-color: var(--active-tab-bg-hover);
        cursor: pointer;
      }

      &--active {
        background-color: var(--active-tab-bg);
        transition: background-color 350ms;
      }
    }
  }

  @media print {
    display: none;
  }
}
[data-theme="light"] {
  --btn-collapse-bg: #{$lightBlue60};
}
[data-theme="dark"] {
  --btn-collapse-bg: #{$greyishBlue90};
}
